import React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"

import { SectionPage, SectionPageTitle, BreadCrumb, Container } from "../components/Section"
import ReviewWdiget from "../components/ReviewWdiget"


const ReviewsPage = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title="Customer Testimonials | ProBuilt Steel Buildings" description="Read authentic customer testimonials about ProBuilt Steel Buildings. Discover why clients trust us for durable and reliable steel structures." />
			<SectionPage
			ept="160px"
			epb="80px"
			xpt="160px"
			xpb="40px"
			pt="100px"
			pb="30px"
			bg="#fff">
				<Container>
					<BreadCrumb className='static'><Link to="/">Home</Link> / <span>Reviews </span></BreadCrumb>
					<SectionPageTitle textAlign="center">What Our Customers Says</SectionPageTitle>
					<ReviewWdiget />
				</Container>
			</SectionPage>
			<CallToAction />
		</Layout>
	)
}

export default ReviewsPage